

	import { PropType } from 'vue';
	import { namespace } from 'vuex-class';
	import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
	import { Project} from '@/modules/projects/types/entities';
	import { Actions, Getters } from '@/modules/projects/store/types/StoreTypes';
	import { Quote } from '@/modules/calendar/interfaces/project/quote';
	import { PlannerPeriod } from '@/modules/calendar/interfaces/project/plannerPeriod';

	const projects = namespace('projects');

	@Component
	export default class PlannerModalComponent extends Vue {
		@projects.Action(Actions.EDIT_QUOTE) editOrder!: (payload: Quote) => Promise<void>;
		@projects.Action(Actions.SET_VALIDATION_ERRORS) setValidationErrors!: (payload?: any) => Promise<void>;

		@projects.Getter(Getters.GET_VALIDATION_ERRORS) validationErrors!: any;

		@Prop({ type: Object as PropType<Project>, required: true }) project!: Project;
		@Prop({ type: Object as PropType<Quote>, required: true }) quote!: Quote;
		@Prop({ type: Number, default: -1 }) periodId!: number;
		@Prop({ type: Boolean, default: false }) value!: boolean;

		savePlannerModal(): void {
			this.editOrder(this.quote).then(() => this.emitInput(false));
		}

		onDateChange(date: string): void {
			if (this.validationErrors) {
				this.setValidationErrors();
			}
		}

		getDateErrorMessage(index: number): string | null { // We can get rid of that method if we are able to return
			if (this.validationErrors && this.validationErrors.errors.hasOwnProperty(`plannerPeriods.${ index }.plannerStartDate`)) {
				return 'Begin cannot be before Einde';
			}
			return null;
		}

		getPlannerPeriodById(periodId: number): PlannerPeriod | undefined {
			return this.quote.plannerPeriods && this.quote.plannerPeriods.find(x => x.id === periodId);
		}

		addNewCommentToPeriod(periodId: number): void {
			const plannerPeriod = this.getPlannerPeriodById(periodId);
			if (plannerPeriod) {
				if (plannerPeriod.comments && !plannerPeriod.comments.length) plannerPeriod.comments.push({ id: -1, body: '', commentableId: -1, commentableType: '' });
			}
		}

		@Watch('quote')
		onQuoteChange(newVal: Quote): void {
			if (this.periodId > 0) {
				this.addNewCommentToPeriod(this.periodId);
			}
		}

		@Watch('periodId', { immediate: true })
		onPeriodIdChange(newVal: number): void {
			this.addNewCommentToPeriod(newVal);
		}

		@Emit('quoteSupplierSelect')
		emitQuoteSupplierSelect(quote: Quote): Quote {
			this.emitInput(false);
			return quote
		}

		@Emit('input')
		emitInput(val: boolean): boolean {
			return val;
		}
	}
